import {useCallback, useEffect, useState} from 'react';
import './modal.css';
import IconButton from '@mui/material/IconButton';
import Modal from 'react-modal';
import {NotificationsNoneOutlined, Sync} from '@mui/icons-material';
import axios from 'axios';
import env from '@app/env.json';
import {useDispatch, useSelector} from 'react-redux';
import {getAccessToken} from '@app/helper/login';
import moment from 'moment';
import {Button, List} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {routeSelector} from '@app/router/redux';
import {NOTIFICATION_REDIRECT} from '@app/common/constants';
import {filterSelector, filterStateActions} from './redux';
import {generateUUID} from '@app/utils/applicationInsight';
import {NetworkService} from '@app/services';
import CloseIcon from '@mui/icons-material/Close';

function NotificationModal(props: any) {
  const [openModal, setOpenModal] = useState(false);
  const [NotificationData, setNotificationData] = useState([]);  
  const navigate = useNavigate();
  const dataRedux: any = useSelector(state => state);
  const dispatch = useDispatch();
  const notification = useSelector(filterSelector.getNotificationData());
  const navbarComponentNameSelector: any = useSelector(
    routeSelector.getNavbarComponentName(),
  );

  useEffect(() => {
    const check =
      dataRedux?.appState?.userInfo?.userId &&
      dataRedux?.appState?.userInfo?.staffPositionId &&
      navbarComponentNameSelector;
    if (check) {
      getNotificationList();
    }
  }, [navbarComponentNameSelector]);

  useEffect(() => {
    if (NotificationData.length === 0 && notification?.length !== 0) {
      setNotificationData(notification);
    }
  }, [notification, NotificationData]);

  const getNotificationList = useCallback(() => {
    void (async () => {
      try {
        const accessToken = await getAccessToken();
        if (accessToken) {
          const result = await NetworkService.get(
            `${env.API_HOST}notification?userId=${dataRedux?.appState?.userInfo?.userId}&staffPositionId=${dataRedux?.appState?.userInfo?.staffPositionId}`,
            {},
            {},
          );

          if (result.status !== 200 || result.data.length === 0) {
            return;
          }
          if (result.status == 200) {
            if (result.data.length > 0) {
              const order = result.data.sort(
                (a: {createdOn: string}, b: {createdOn: string}) =>
                  Date.parse(b.createdOn) - Date.parse(a.createdOn),
              );
              setNotificationData(order);
              dispatch(filterStateActions.setNotification(order));
            }
          }
        }
      } catch (err) {
        props.notification ?? setNotificationData(props.notification);
        return err;
      }
    })();
  }, []);

  const handleViewButtonOnClick = (NotifiData: any) => {
    navigate(NOTIFICATION_REDIRECT.cnfReturnApproval);
  };

  const MarkNotification = (params: any) => {
    void (async () => {
      try {
        const accessToken = await getAccessToken();
        axios
          .put(`${env.API_HOST}notification/markasread`, [params?.id], {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              CorrelationId: generateUUID(),
            },
          })

          .catch(err => {});
      } catch (error) {}
    })();
  };
  const openModel = useCallback(() => {
    setOpenModal(false);
  }, []);

  const openModels = useCallback(() => {
    setOpenModal(!openModal);
  }, []);

  return (
    <div>
      <div className="bellend">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          data-testid="open-popup"
          onClick={openModels}
          edge="end">
          <NotificationsNoneOutlined color="primary" />
          {NotificationData.filter((e: any) => !e?.isRead).length > 0 && (
            <div
              style={{
                position: 'absolute',
                right: 6.7,
                top: 6.7,
                height: 6.7,
                width: 6.7,
                backgroundColor: 'red',
                borderRadius: 50,
              }}>
              .
            </div>
          )}
        </IconButton>
      </div>

      {openModal && (
        <Modal
          isOpen={true}
          style={{
            content: {
              widows: 'auto',
              height: 'auto',
              border: 'opx',
              right: 0,
              // alignSelf: 'flex-end',
              overflow: 'hidden',
              backgroundColor: 'rgba(0,0,0,0)',
            },
            overlay: {zIndex: 2000},
          }}>
          <div className="modalContainer">
            <div className="titleCloseBtn">
              <div className="body">
                <p>Notifications</p>
              </div>
              <button onClick={() => getNotificationList()}>
                <div className="">
                  <Sync className="syncLogo" />
                </div>
              </button>
              <button data-testid="close-popup" onClick={openModel}>
                <div className="body close">
                  <CloseIcon />
                </div>
              </button>
            </div>
            <div className="TodaysDate">
              <p>Today, {moment().format('Do MMMM YYYY')}</p>
            </div>
            <div className="body">
              <List className={NotificationData.length > 0 ? 'listClass' : ''}>
                {NotificationData.length > 0 ? (
                  Object.entries(
                    NotificationData.reduce((group: any, product: any) => {
                      const {createdOn}: any = product;
                      const onlyDate = moment(createdOn).format('YYYY-MM-DD')
                      group[onlyDate] = group[onlyDate] ?? [];
                      group[onlyDate].push(product);
                      return group;
                    }, {}),
                  ).map((data: any) => {
                    return (
                      <div key={data}>
                        <p>{moment(data[0]).format('Do MMMM YYYY')}</p>
                        {data[1].map((NotifiData: any, index: number) => {
                          return (
                            <div
                              data-testid={`notifyData`}
                              onClick={() => {
                                MarkNotification(NotifiData);
                              }}
                              className="modalContainerEachRow"
                              key={NotifiData?.message}>
                              <p className="timeRow">
                                {moment
                                  .utc(NotifiData?.createdOn)
                                  .local()
                                  .format('LT')}
                              </p>
                              <p>{NotifiData?.message}</p>
                              {NotifiData?.data?.actionType ===
                                'InventoryTransferToCNF' &&
                                !NotifiData?.isRead && (
                                  <div className="viewButtonParent">
                                    <Button
                                      size={'small'}
                                      className="viewButtonStyle"
                                      onClick={() => {
                                        handleViewButtonOnClick(NotifiData);
                                      }}
                                      data-testId={`viewButton-${index}`}>
                                      View
                                    </Button>
                                  </div>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <p>No Notification</p>
                  </div>
                )}
              </List>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default NotificationModal;
