import {Search} from '@mui/icons-material';
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Autocomplete,
} from '@mui/material';
import React, {ChangeEvent, useEffect} from 'react';
import {debounce} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {inventoryPRSelector} from '../redux/selectors';
import {
  fetchSubordinateCreator,
  fetchUserListDataCreator,
  inventoryModulePRActions,
} from '../redux/slice';
interface UserList {
  userId: string;
  roleName: any;
  searchData: any;
  displayData: any;
}
const styles = {
  formContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginLeft: 1,
    width: '100%',
  },
  formControl: {
    margin: '8px',
    backgroundColor: '#ffffff',
    borderWidth: 0.7,
    borderColor: '#322b7c',
    width: '100%',
  },
  suggestionsContainer: {
    left: '50%',
    backgroundColor: '#d5e2e7',
    width: '32%',
    padding: '20px',
    position: 'fixed',
  },
};

export enum SearchType {
  userName = 'UserName',
  sapCode = 'SapCode',
}
const searchTypeLabel = {
  UserName: 'User Name',
  SapCode: 'SAP Code',
};

export const utility: any = {
  handleDDChange: null,
  handleTextChange: null,
  debouncedCallbackChange: null,
};

const SearchOption = ({searchTextdata = '', search = ''}) => {
  const dispatch = useDispatch();
  const [searchType, setSearchType] = React.useState<string>(searchTextdata);
  const [searchText, setSearchText] = React.useState<string>(search);
  const [open, setOpen] = React.useState(false);
  const [autocompleteValue, setAutocompleteValue] =
    React.useState<UserList | null>(null);
  const divisions = useSelector(inventoryPRSelector.getSelectedDivision());
  const usersAutosuggest = useSelector(inventoryPRSelector.getUserListData());
  const isActiveUsers = useSelector(inventoryPRSelector.getActiveUsers());
  const loader = useSelector(inventoryPRSelector.getLoading());
  const handleDDChange = (e: SelectChangeEvent) => {
    setAutocompleteValue(null);
    setSearchText('' as string);
    setSearchType(e?.target?.value as SearchType);
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setSearchText(value);
    if (value.length >= 3) {
      let isValidInput = false;
      if (searchType === SearchType.sapCode) {
        isValidInput = /^\d+$/.test(value);
      } else if (searchType === SearchType.userName) {
        isValidInput = /^[A-Za-z\s]+$/.test(value);
      }

      if (isValidInput) {
        const userListData = {
          SearchType: searchType,
          SearchValue: value,
          IsActive: isActiveUsers,
          _divisionId: divisions[0],
        };
        setOpen(true);
        dispatch(fetchUserListDataCreator(userListData));
        dispatch(inventoryModulePRActions.setUserData(userListData));
      }
    }
  };

  useEffect(() => {
    setAutocompleteValue(null);
    setSearchType('');
    setSearchText('' as string);
  }, [divisions]);

  useEffect(() => {
    if (autocompleteValue && autocompleteValue.roleName !== 'MR') {
      dispatch(fetchSubordinateCreator({userIds: autocompleteValue?.userId}));
    } else {
      dispatch(inventoryModulePRActions.setSubordinates({}));
    }
    dispatch(inventoryModulePRActions.setSelectedStaff(autocompleteValue));
  }, [autocompleteValue]);

  const debouncedCallbackChange = debounce(handleTextChange, 1200);
  utility.handleDDChange = handleDDChange;
  utility.handleTextChange = handleTextChange;
  utility.debouncedCallbackChange = debouncedCallbackChange;
  const handleKeyDown = (event: any) => {
    const controlKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Control',
      'Meta',
      'Shift',
      'Tab',
    ];

    if (searchType === SearchType.userName) {
      if (/\d/.test(event.key)) {
        event.preventDefault();
      }
    } else if (searchType === SearchType.sapCode) {
      if (!/\d/.test(event.key) && !controlKeys.includes(event.key)) {
        event.preventDefault();
      }
    }
  };
  return (
    <Box sx={styles.formContainer}>
      <FormControl sx={styles.formControl} style={{marginRight: '12px'}}>
        <InputLabel id="search-type-select-label">Search By</InputLabel>
        <Select
          inputProps={{
            'data-testid': 'dropdown-search-type',
          }}
          variant="outlined"
          labelId="search-type-select-label"
          label="Search By"
          value={searchType}
          disabled={divisions?.length === 0 || loader}
          onChange={handleDDChange}>
          <MenuItem value={SearchType.userName}>
            {searchTypeLabel[SearchType.userName]}
          </MenuItem>
          <MenuItem value={SearchType.sapCode}>
            {searchTypeLabel[SearchType.sapCode]}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={styles.formControl}>
        <Autocomplete
          data-testid="search-type"
          options={usersAutosuggest || []}
          disabled={divisions?.length === 0}
          getOptionLabel={option => option.displayData}
          onChange={(_event, newValue) => {
            if (newValue) {
              setAutocompleteValue(newValue);
              setSearchText(newValue.displayData);
            } else {
              setAutocompleteValue(null);
            }
            dispatch(inventoryModulePRActions.setSearchValue(newValue));
          }}
          value={autocompleteValue}
          open={open}
          onOpen={() => {
            if (searchType && searchText) {
              setOpen(true);
            }
          }}
          onClose={() => setOpen(false)}
          renderInput={params => (
            <TextField
              {...params}
              disabled={!searchType} // Disable when no search type or no text and dropdown closed
              data-testid="search-texfield"
              placeholder="Search By Name/SAP Code"
              onChange={debouncedCallbackChange}
              onKeyDown={handleKeyDown} // Attach the key down handler
              sx={{width: '100%'}}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default SearchOption;
