import {useCallback, useMemo, useState, useEffect} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {useDispatch, useSelector} from 'react-redux';
import {Daily_Expense} from '../../module-specific-utilities.types';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import '../approve-expenses/styles/gridStyle.css';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {Box, Button, Link} from '@mui/material';
import {
  approveExpenseStateActions,
  fetchBilDetailsCreator,
  fetchDailyExpenseDcrMapUrlDataCreator,
  fetchDailyExpenseDcrReportDataCreator,
  fetchDailyExpensePopupReadOnlyData,
  fetchDailyExpensePopupTADAData,
  fetchDailyExpensePopupTADASaveData,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {DcrModal} from './DCR-Report-Modal';
import InsufficentFundPopup from './sub-components/InsufficentFundPopup';
import {ViewBillPopup} from './sub-components/ViewBillPopup';
import {TADAPopup} from './ta-da-popup';
import {
  EditIcon,
  colorChange,
  nameStyles,
  referColor,
} from '@app/screens/Styles/style';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {Gridstyle} from '@app/components/widgets/grid/grid';
import {NoRecordsFoundBox, TextInput} from './ApproveExpenseMiscEditableGrid';
import {NumberInput} from './utils/numericcellEdit';
import TravleDetail from './sub-components/TravelDetails';
import {strings} from '@app/common/strings';
import theme from '@app/themes';

const initialTableWidth = {
  [strings.moduleSpecificUtilities.activityName]: 100,
  [strings.moduleSpecificUtilities.fromStation]: 100,
  [strings.moduleSpecificUtilities.toStationDisplayStr]: 100,
  [strings.moduleSpecificUtilities.taChangeReason]: 200,
  [strings.moduleSpecificUtilities.managerChangeReasonTA]: 200,
  [strings.moduleSpecificUtilities.adminChangeReasonTA]: 200,
  [strings.moduleSpecificUtilities.dAchangeReason]: 200,
  [strings.moduleSpecificUtilities.managerPassedReasonDA]: 200,
  [strings.moduleSpecificUtilities.adminChangeReasonDA]: 200,
  [strings.moduleSpecificUtilities.workWith]: 200,
  [strings.moduleSpecificUtilities.wsfaRemarks]: 200,
  [strings.moduleSpecificUtilities.DCRRemarks]: 200,
};

export const util: any = {
  onRowValueChanged: null,
  onRowEditingStarted: null,
  style1: null,
  style: null,
  handleCellChange: null,
  AdminPassedDaConditionalRendering: null,
};

export const adminPassedDistance = (isCellEditable: any, style: any) => {
  return {
    field: strings.moduleSpecificUtilities.adminPassedDistance,
    headerName: strings.moduleSpecificUtilities.ADMINPASSEDDISTANCE,
    width: 90,
    editable: isCellEditable,
    cellEditor: NumberInput,
    cellStyle: (params: any) => {
      if (isCellEditable(params)) {
        return {backgroundColor: theme.colors.yellow[700]};
      }
      return null;
    },
  };
};

const ApproveExpenseDailyGrid = () => {
  const dispatch = useDispatch();
  const [expandedWidth, setExpandedWidth] = useState(initialTableWidth);
  const [editedDCRids, setEditedDCRids] = useState<any>([]);
  const [expenseId, setExpenseId] = useState<any>(null);
  const expenseApprovalsDaily: Daily_Expense[] = useSelector(
    approveExpenseStateSelector.getDailyExpenseApprovalsData(),
  );

  const dailyEditedRowColorIndex =
    useSelector(approveExpenseStateSelector.setDailyEditedRowColorIndex()) ??
    [];
  const dailyEditedRowColorIndexArr1 =
    useSelector(
      approveExpenseStateSelector.getDailyEditedRowColorIndexArr1(),
    ) ?? [];
  const SelectedExpenseApprovalsUser = useSelector(
    approveExpenseStateSelector.getSelectedExpenseApprovalsUser(),
  );
  const PopuptadaData = useSelector(
    approveExpenseStateSelector.getPopuptadaData(),
  );
  const status = useSelector(
    approveExpenseStateSelector.getApproveExpensestatus(),
  );
  const [rowData, setRowData] = useState<any>([]);
  const rowVal = () => {
    let datagrid = expenseApprovalsDaily?.map(({dcrDayCode, ...rest}) => ({
      ...rest,
      ['isEditable']: status?.expenseStatusId === 9,
    }));
    return datagrid;
  };

  useEffect(() => {
    setRowData(rowVal());
  }, [expenseApprovalsDaily, status]);

  useEffect(() => {
    return () => {
      dispatch(approveExpenseStateActions.setDailyEditedRowColorIndex([]));
      dispatch(approveExpenseStateActions.setDailyEditedRowColorIndexArr1([]));
    };
  }, []);

  const getRowId = useMemo(() => {
    return (params: any) => params?.data?.id;
  }, []);
  const EditTable = (params: any) => {
    setExpenseId(params?.data?.dcrId);
    dispatch(approveExpenseStateActions.setTaDaPopupVisible(true));
    dispatch(fetchDailyExpensePopupReadOnlyData(params?.data?.dcrId));
    dispatch(fetchDailyExpensePopupTADAData(params?.data?.dcrId));
    dispatch(approveExpenseStateActions.setTaRate(params?.data?.taRate));
    dispatch(approveExpenseStateActions.setexpenseApprovalsViewBillData([]));
  };

  const openMapPopup = (params: any) => () => {
    dispatch(fetchDailyExpenseDcrMapUrlDataCreator(params?.data));
  };
  const onViewBillClickfuntion = (params: any) => {
    let request = {
      id: params?.data?.dcrId,
      page: strings.dialy,
    };
    setExpenseId(params?.data?.dcrId);
    dispatch(fetchBilDetailsCreator(request));
    dispatch(approveExpenseStateActions.setViewBillPopupVisible(true));
  };
  const travelDetails = (params: any) => {
    dispatch(
      approveExpenseStateActions.setTravelDetails(params?.data?.travelItems),
    );
    dispatch(approveExpenseStateActions.setTravelDetailPopup(true));
  };
  const OpenDcrPopup = (params: any) => {
    if (params?.data?.dcrId !== 0) {
      const payload = {
        dcrDate: `${params?.data?.expenseYear}-${params?.data?.expenseMonth}-${params?.data?.expenseDay}`,
        staffPositionId: SelectedExpenseApprovalsUser?.staffPositionId,
        staffUserId: SelectedExpenseApprovalsUser?.staffUserId,
      };
      dispatch(fetchDailyExpenseDcrReportDataCreator(payload));
      dispatch(approveExpenseStateActions.setDcrReportPopupVisible(true));
    }
  };
  const isCellEditable = useCallback(
    (params: any) => {
      return (
        params?.data?.isEditable &&
        params?.data?.activity !== strings.HOLIDAY &&
        params?.data?.activity !== strings.WEEKOFF &&
        params?.data?.activity !== strings.WEEKOFFHOLIDAY &&
        params?.data?.activity !== strings.LEAVE &&
        params?.data?.dcrId != '0' &&
        !params.data.isMultipleTaDaExists &&
        !params?.data?.isWeekOff
      );
    },
    [status],
  );
  const columnTypes = useMemo(() => {
    return {
      valueColumn: {
        editable: true,
        valueParser: 'newValue',
      },
    };
  }, []);
  const style1 = (params: any) => {
    if (isCellEditable(params)) {
      if (dailyEditedRowColorIndexArr1.includes(params.data?.expenseDay)) {
        return {backgroundColor: theme.colors.red[1300]};
      }
      return {backgroundColor: theme.colors.yellow[700]};
    }
  };
  const style = (params: any) => {
    if (isCellEditable(params)) {
      if (dailyEditedRowColorIndex?.includes(params?.data?.expenseDay)) {
        return {backgroundColor: theme.colors.red[1300]};
      }
      return {backgroundColor: theme.colors.yellow[700]};
    }
  };
  const expenseDay = (params: any) => {
    return (
      <Button
        data-testid={`day-${params?.data?.expenseDay}`}
        size="small"
        sx={{p: 0, m: 0, justifyContent: 'left'}}
        onClick={() => OpenDcrPopup(params)}>
        {params?.data?.expenseDay}
      </Button>
    );
  };
  const renderTitle = (params: any) => {
    return <span title={params?.value}>{params?.value}</span>;
  };
  const link = (params: any) => {
    return (
      <Link
        title={params?.value}
        className={'toLink'}
        onClick={openMapPopup(params)}>
        {params?.value}
      </Link>
    );
  };
  const travelDetail = (params: any) => {
    if (params?.data?.travelItems?.length > 0) {
      return (
        <Link
          title={params?.value}
          className={'toLink'}
          onClick={() => travelDetails(params)}>
          {params?.value}
        </Link>
      );
    } else {
      return <span title={params?.value}>{params?.value}</span>;
    }
  };
  const edit = (params: any) => {
    if (params?.data?.dcrId !== 0) {
      return params?.data?.isMultipleTaDaExists ? (
        <Button
          data-testid={`button-${params?.data?.dcrId}`}
          sx={[
            {color: `${theme.colors.pinkish} !important`},
            Gridstyle.IconButton,
          ]}
          onClick={() => EditTable(params)}>
          <EditIcon />
        </Button>
      ) : (
        <Button sx={[Gridstyle.IconButton]}>
          <EditIcon />
        </Button>
      );
    }
    return null;
  };
  const billPath = (params: any) => {
    if (params?.data?.isBillsUploaded) {
      return !params?.data?.isMultipleTaDaExists ? (
        <VisibilityOutlinedIcon
          data-testid={`outlined-${params?.data?.expenseDetailId}`}
          sx={[
            {color: `${theme.colors.LIGHT_NAVY_BLUE} !important`},
            Gridstyle.IconButton,
          ]}
          fontSize="small"
          onClick={() => onViewBillClickfuntion(params)}
        />
      ) : (
        <VisibilityOutlinedIcon
          data-testid={`outlined-${params?.data?.expenseDetailId}`}
          sx={[
            {color: `${theme.colors.silverish} !important`},
            Gridstyle.IconButton,
          ]}
          fontSize="small"
        />
      );
    }
    return null;
  };

  const columns: any = [
    {
      field: strings.moduleSpecificUtilities.expenseDay,
      headerName: strings.moduleSpecificUtilities.Day,
      width: 60,
      pinned: 'left',
      editable: false,
      maxWidth: 100,
      cellRenderer: expenseDay,
    },
    {
      field: strings.moduleSpecificUtilities.activityName,
      headerName: strings.moduleSpecificUtilities.Activity,
      pinned: 'left',
      editable: false,
      width: expandedWidth.activityName,
      maxWidth: 400,
      suppressSizeToFit: true,
      cellRenderer: renderTitle,
    },
    {
      field: strings.moduleSpecificUtilities.fromStation,
      headerName: strings.moduleSpecificUtilities.FROMSTATION,
      pinned: 'left',
      editable: false,
      width: expandedWidth.fromStation,
      maxWidth: 350,
      suppressSizeToFit: true,
      cellRenderer: renderTitle,
    },
    {
      field: strings.moduleSpecificUtilities.toStationDisplayStr,
      headerName: strings.moduleSpecificUtilities.TOSTATION,
      editable: false,
      width: expandedWidth.toStationDisplayStr,
      maxWidth: 600,
      pinned: 'left',
      suppressSizeToFit: true,
      cellRenderer: link,
    },
    {
      field: strings.HQType,
      headerName: strings.hqType,
      width: 60,
      pinned: 'left',
      maxWidth: 80,
      editable: false,
    },
    {
      field: strings.moduleSpecificUtilities.distance,
      headerName: strings.DISTANCE,
      width: 70,
      pinned: 'left',
      maxWidth: 200,
      editable: false,
    },
    {
      field: strings.TA,
      headerName: strings.ta,
      width: 80,
      maxWidth: 200,
      editable: false,
    },
    {
      field: strings.moduleSpecificUtilities.taClaimed,
      headerName: strings.moduleSpecificUtilities.TACLAIMED,
      width: 80,
      editable: false,
      maxWidth: 200,
    },
    {
      field: strings.moduleSpecificUtilities.taChangeReason,
      headerName: strings.moduleSpecificUtilities.TACHANGEREASON,
      width: expandedWidth.taChangeReason,
      maxWidth: 400,
      editable: false,
      cellRenderer: travelDetail,
    },
    {
      field: strings.moduleSpecificUtilities.managerPassedTA,
      headerName: strings.moduleSpecificUtilities.MANAGERPASSEDTA,
      editable: false,
      maxWidth: 100,
    },
    {
      field: strings.moduleSpecificUtilities.managerChangeReasonTA,
      headerName: strings.moduleSpecificUtilities.MANAGERREMARKSTA,
      width: expandedWidth.managerChangeReasonTA,
      maxWidth: 450,
      editable: false,
      cellRenderer: renderTitle,
    },
    adminPassedDistance(isCellEditable, style),
    {
      field: strings.moduleSpecificUtilities.adminChangeReasonTA,
      headerName: strings.moduleSpecificUtilities.ADMINCHANGEREASONTA,
      maxWidth: 450,
      width: expandedWidth.adminChangeReasonTA,
      cellEditor: TextInput,
      editable: isCellEditable,
      cellStyle: (params: any) => style(params),
      cellEditorParams: {
        maxLength: 50,
        minLength: 5,
      },
      cellRenderer: renderTitle,
      valueParser: (params: any) => {
        return params?.newValue;
      },
    },
    {
      field: strings.moduleSpecificUtilities.adminPassedTA,
      headerName: strings.moduleSpecificUtilities.ADMINPASSEDTA,
      editable: false,
      maxWidth: 100,
      valueGetter: (params: any) => {
        if (
          params?.data?.activity === strings.HOLIDAY ||
          params?.data?.activity === strings.WEEKOFF ||
          params?.data?.activity === strings.WEEKOFFHOLIDAY ||
          params?.data?.activity === strings.LEAVE
        ) {
          return null;
        } else {
          return editedDCRids?.includes(params?.data?.dcrId)
            ? adminPassedTACalculations(params?.data, editedDCRids)
            : params?.data?.adminPassedTA;
        }
      },
    },
    {
      field: strings.DA,
      headerName: strings.da,
      editable: false,
      maxWidth: 100,
    },
    {
      field: strings.moduleSpecificUtilities.dAclaimed,
      headerName: strings.moduleSpecificUtilities.DACLAIMED,
      editable: false,
      maxWidth: 100,
    },
    {
      field: strings.moduleSpecificUtilities.dAchangeReason,
      headerName: strings.moduleSpecificUtilities.DACHANGEREASON,
      editable: false,
      maxWidth: 450,
      width: expandedWidth.dAchangeReason,
      cellRenderer: renderTitle,
    },
    {
      field: strings.moduleSpecificUtilities.managerPassedDA,
      headerName: strings.moduleSpecificUtilities.MANAGERPASSEDDA,
      editable: false,
      maxWidth: 120,
    },
    {
      field: strings.moduleSpecificUtilities.managerPassedReasonDA,
      headerName: strings.moduleSpecificUtilities.MANAGERREMARKS,
      editable: false,
      maxWidth: 450,
      width: expandedWidth.managerPassedReasonDA,
      cellRenderer: renderTitle,
    },
    {
      field: strings.moduleSpecificUtilities.hqTypeAdmin,
      headerName: strings.moduleSpecificUtilities.HQTYPEADMIN,
      maxWidth: 130,
      editable: isCellEditable,
      valueGetter: (params: any) => {
        if (
          params?.data?.hqTypeAdmin == strings.ex ||
          params?.data?.hqTypeAdmin == 'E'
        ) {
          return strings.ex;
        }
        if (
          params?.data?.hqTypeAdmin == strings.home ||
          params?.data?.hqTypeAdmin == 'H'
        ) {
          return strings.home;
        }
        if (
          params?.data?.hqTypeAdmin == strings.out ||
          params?.data?.hqTypeAdmin == 'O'
        ) {
          return strings.out;
        }
        return null;
      },
      cellStyle: (params: any) => {
        if (isCellEditable(params)) {
          return {backgroundColor: theme.colors.yellow[700]};
        }
        return null;
      },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        cellHeight: 50,
        values: [strings.home, strings.out, strings.ex],
      },
    },
    {
      field: strings.moduleSpecificUtilities.adminChangeReasonDA,
      headerName: strings.moduleSpecificUtilities.ADMINCHANGEREASONDA,
      maxWidth: 450,
      width: expandedWidth.adminChangeReasonDA,
      cellEditor: TextInput,
      editable: isCellEditable,
      cellStyle: (params: any) => style1(params),
      cellRenderer: renderTitle,
      cellEditorParams: {
        maxLength: 50,
      },
    },
    {
      field: strings.moduleSpecificUtilities.adminPassedDA,
      headerName: strings.moduleSpecificUtilities.ADMINPASSEDDA,
      maxWidth: 100,
      editable: isCellEditable,

      valueGetter: (params: any) => {
        return AdminPassedDaConditionalRendering(
          params?.data,
          expenseApprovalsDaily,
          params?.rowIndex,
        );
      },
      cellStyle: (params: any) => {
        if (isCellEditable(params)) {
          return {backgroundColor: theme.colors.yellow[700]};
        }
        return null;
      },
      cellEditor: NumberInput,
    },

    {
      field: strings.moduleSpecificUtilities.workWith,
      headerName: strings.moduleSpecificUtilities.WORKWITH,
      editable: false,
      maxWidth: 400,
      width: expandedWidth.workWith,
      cellRenderer: renderTitle,
    },
    {
      field: strings.moduleSpecificUtilities.noOfDoctors,
      headerName: strings.moduleSpecificUtilities.NOOFDOCTORS,
      editable: false,
      maxWidth: 100,
    },
    {
      field: strings.moduleSpecificUtilities.noofChemists,
      headerName: strings.moduleSpecificUtilities.NOOFCHEMISTS,
      editable: false,
      maxWidth: 100,
    },
    {
      field: strings.moduleSpecificUtilities.wsfaRemarks,
      headerName: strings.moduleSpecificUtilities.DCRRemarks,
      editable: false,
      maxWidth: 500,
      width: expandedWidth.wsfaRemarks,
    },
    {
      field: strings.moduleSpecificUtilities.REMARKS,
      headerName: strings.remarks,
      editable: false,
      maxWidth: 300,
      width: expandedWidth.DCRRemarks,
      cellRenderer: renderTitle,
    },
    {
      field: strings.moduleSpecificUtilities.taRate,
      headerName: strings.moduleSpecificUtilities.TARATE,
      editable: false,
      maxWidth: 100,
    },
    {
      field: strings.moduleSpecificUtilities.billPath,
      headerName: strings.moduleSpecificUtilities.BILL,
      editable: false,
      maxWidth: 100,
      cellRenderer: billPath,
    },
    {
      field: strings.edit,
      headerName: strings.edit,
      editable: false,
      cellClass: 'noteditable',
      maxWidth: 100,
      cellRenderer: edit,
    },
  ];
  const AdminPassedDaConditionalRendering = (
    newRow: any,
    expenseApprovalsDaily: any,
    index: any,
  ) => {
    let value = Number(newRow?.adminPassedDA);
    if (
      expenseApprovalsDaily?.[index]?.hqTypeAdmin &&
      newRow?.hqTypeAdmin !== expenseApprovalsDaily?.[index]?.hqTypeAdmin
    ) {
      if (
        newRow?.hqTypeAdmin === 'E' ||
        (newRow?.hqTypeAdmin === strings.ex && newRow?.daExAmount !== null)
      )
        value = newRow?.daExAmount;
      if (
        newRow?.hqTypeAdmin === 'H' ||
        (newRow?.hqTypeAdmin === strings.home && newRow?.dahqAmount !== null)
      )
        value = newRow?.dahqAmount;
      if (
        newRow?.hqTypeAdmin === 'O' ||
        (newRow?.hqTypeAdmin === strings.out && newRow?.daOutAmount !== null)
      )
        value = newRow?.daOutAmount;
    } else {
      value = newRow?.adminPassedDA;
    }
    return value;
  };
  const look = (
    dcrId: any,
    tempArr: any,
    taVal: any,
    daVAl: any,
    taRes: any,
    daRes: any,
  ) => {
    let looks = PopuptadaData?.map((items: any) => {
      if (items?.dcrId == dcrId) {
        return {
          taDaLookUpId: items?.id,
          adminApprovedAmount:
            items?.expenseCategory == strings.ta ? taVal : daVAl,
          adminRemarks: items?.expenseCategory == strings.ta ? taRes : daRes,
        };
      }
    });
    return looks;
  };

  const hqType = (newRow: any) => {
    if (newRow == strings.ex || newRow == 'E') {
      return 'E';
    }
    if (newRow == strings.home || newRow == 'H') {
      return 'H';
    }
    if (newRow == strings.out || newRow == 'O') {
      return 'O';
    }
  };

  const request = (data: any, expenseApprovalsDaily: any, index: any) => {
    let data1 =
      expenseApprovalsDaily?.[index]?.adminPassedDistance !=
      data?.adminPassedDistance
        ? adminPassedTACalculations(data, index)
        : data?.adminPassedTA;
    let data2 = Number(
      AdminPassedDaConditionalRendering(data, expenseApprovalsDaily, index) ??
        0,
    );
    return {
      dcrId: data?.dcrId,
      adminApprovedTAAmount: data1,
      adminApprovedDAAmount: data2,
      adminApprovedDistance: Number(data?.adminPassedDistance),
      adminApprovedHQType: hqType(data?.hqTypeAdmin) ?? '',
      AdminApprovedTARemarks: data?.adminChangeReasonTA,
      AdminApprovedDARemarks: data?.adminChangeReasonDA,
      staffPositionId: data?.staffPositionId,
      userId: data?.staffUserId,
      month: data?.expenseMonth,
      year: data?.expenseYear,

      saveTaDaLookUp: look(
        data?.dcrId,
        data,
        data1,
        data2,
        data?.adminChangeReasonTA,
        data?.adminChangeReasonDA,
      ),
    };
  };
  const resaonCondi = (oldCode: any, data: any) => {
    if (oldCode?.adminPassedDistance == data?.adminPassedDistance) {
      if (dailyEditedRowColorIndex.includes(data?.expenseDay)) {
        let updatedChildren = [...dailyEditedRowColorIndex];
        const index = updatedChildren.indexOf(data?.expenseDay);
        if (index !== -1) {
          updatedChildren.splice(index, 1);
        }
        dispatch(
          approveExpenseStateActions.setDailyEditedRowColorIndex(
            updatedChildren,
          ),
        );
      }
    }
  };
  const taCondition = (data: any, oldCode: any, index: any) => {
    if (
      (data?.expenseDay == oldCode.expenseDay &&
        !data?.adminChangeReasonTA &&
        oldCode?.adminPassedDistance != data?.adminPassedDistance) ||
      (!data?.adminChangeReasonTA &&
        oldCode?.adminChangeReasonTA != data?.adminChangeReasonTA)
    ) {
      setEditedDCRids((prev: number[]) => [...prev, data?.dcrId]);
      ToasterService.showToaster(
        strings.moduleSpecificUtilities.AdminChangeReasonTAisMandator,
        ToastType.ERROR,
      );
      if (!dailyEditedRowColorIndex.includes(data?.expenseDay)) {
        dispatch(
          approveExpenseStateActions.setDailyEditedRowColorIndex(
            dailyEditedRowColorIndex.concat(data?.expenseDay),
          ),
        );
      }
      dispatch(approveExpenseStateActions.setDisableaApproveExpense(true));
    }
    if (
      data?.expenseDay == oldCode?.expenseDay &&
      data?.adminChangeReasonTA?.length > 0 &&
      data?.adminChangeReasonTA?.length < 5 &&
      oldCode?.adminChangeReasonTA != data?.adminChangeReasonTA
    ) {
      ToasterService.showToaster(
        strings.moduleSpecificUtilities.minMaxChar,
        ToastType.ERROR,
      );
      setEditedDCRids((prev: number[]) => [...prev, data?.dcrId]);
      if (!dailyEditedRowColorIndex?.includes(data?.expenseDay)) {
        dispatch(
          approveExpenseStateActions.setDailyEditedRowColorIndex(
            dailyEditedRowColorIndex?.concat(data?.expenseDay),
          ),
        );
      }
    }
    resaonCondi(oldCode, data);
  };

  const adminChangeReasonMandCheck = (
    data: any,
    oldCode: any,
    index: number,
  ) => {
    if (
      (!data?.adminChangeReasonDA &&
        oldCode?.adminPassedDA != data?.adminPassedDA) ||
      (!data?.adminChangeReasonDA &&
        oldCode?.hqTypeAdmin != hqType(data?.hqTypeAdmin))
    ) {
      ToasterService.showToaster(
        strings.moduleSpecificUtilities.AdminChangeReasonDAisMandatory,
        ToastType.ERROR,
      );
      dispatch(approveExpenseStateActions.setDisableaApproveExpense(true));
      setEditedDCRids((prev: number[]) => [...prev, data?.dcrId]);
      if (!dailyEditedRowColorIndexArr1?.includes(data?.expenseDay)) {
        dispatch(
          approveExpenseStateActions.setDailyEditedRowColorIndexArr1(
            dailyEditedRowColorIndexArr1?.concat(data?.expenseDay),
          ),
        );
      }
    }
    if (oldCode?.hqTypeAdmin == hqType(data?.hqTypeAdmin)) {
      if (dailyEditedRowColorIndexArr1.includes(data?.expenseDay)) {
        let updatedChildren = [...dailyEditedRowColorIndexArr1];
        const index = updatedChildren.indexOf(data?.expenseDay);
        if (index !== -1) {
          updatedChildren.splice(index, 1);
        }
        dispatch(
          approveExpenseStateActions.setDailyEditedRowColorIndexArr1(
            updatedChildren,
          ),
        );
      }
    }
  };

  const condition = (data: any, oldCode: any, index: any) => {
    taCondition(data, oldCode, index);
    adminChangeReasonMandCheck(data, oldCode, index);
    if (
      data?.adminChangeReasonDA?.length > 0 &&
      data?.adminChangeReasonDA?.length < 5 &&
      oldCode?.adminChangeReasonDA != data?.adminChangeReasonDA &&
      (oldCode?.hqTypeAdmin != hqType(data?.hqTypeAdmin) ||
        oldCode?.adminPassedDA != data?.adminPassedDA)
    ) {
      ToasterService.showToaster(
        strings.moduleSpecificUtilities.minMaxChar,
        ToastType.ERROR,
      );
      setEditedDCRids((prev: number[]) => [...prev, data?.dcrId]);
      if (!dailyEditedRowColorIndexArr1?.includes(data?.expenseDay)) {
        dispatch(
          approveExpenseStateActions.setDailyEditedRowColorIndexArr1(
            dailyEditedRowColorIndexArr1?.concat(data?.expenseDay),
          ),
        );
      }
    }

    if (
      data?.adminChangeReasonDA?.length > 0 &&
      data?.adminChangeReasonDA?.length < 5 &&
      oldCode?.adminChangeReasonDA != data?.adminChangeReasonDA &&
      oldCode?.hqTypeAdmin == hqType(data?.hqTypeAdmin) &&
      oldCode?.adminPassedDA == data?.adminPassedDA
    ) {
      ToasterService.showToaster(
        strings.moduleSpecificUtilities.minMaxChar,
        ToastType.ERROR,
      );
      setEditedDCRids((prev: number[]) => [...prev, data?.dcrId]);
      if (!dailyEditedRowColorIndexArr1?.includes(data?.expenseDay)) {
        dispatch(
          approveExpenseStateActions.setDailyEditedRowColorIndexArr1(
            dailyEditedRowColorIndexArr1?.concat(data?.expenseDay),
          ),
        );
      }
    }
    if (
      data?.adminChangeReasonTA &&
      data?.adminChangeReasonTA?.length >= 5 &&
      data?.adminChangeReasonTA?.length <= 50 &&
      oldCode?.adminChangeReasonTA != data?.adminChangeReasonTA
    ) {
      dispatch(approveExpenseStateActions.setDisableaApproveExpense(false));
      dispatch(
        approveExpenseStateActions.setDailyEditedRowColorIndex(
          filterArr(data?.expenseDay, dailyEditedRowColorIndex),
        ),
      );
      setTimeout(() => {
        dispatch(
          fetchDailyExpensePopupTADASaveData(
            request(data, expenseApprovalsDaily, index),
          ),
        );
      }, 500);
    } else if (
      data?.hqTypeAdmin &&
      data?.adminChangeReasonDA &&
      data?.adminChangeReasonDA?.length >= 5 &&
      data?.adminChangeReasonDA?.length <= 50 &&
      oldCode?.adminChangeReasonDA != data?.adminChangeReasonDA
    ) {
      dispatch(
        approveExpenseStateActions.setDailyEditedRowColorIndexArr1(
          filterArr(data?.expenseDay, dailyEditedRowColorIndexArr1),
        ),
      );
      setTimeout(() => {
        dispatch(
          fetchDailyExpensePopupTADASaveData(
            request(data, expenseApprovalsDaily, index),
          ),
        );
      }, 500);
    }
  };
  const filterArr = (expenseDay: any, arr: any) => {
    return arr?.filter((user: any) => user !== expenseDay);
  };

  const modifiedEmployees = (data: any, oldCode: any, index: any) => {
    return expenseApprovalsDaily?.map(obj => {
      if (obj?.dcrId == data?.dcrId) {
        return {
          ...obj,
          adminPassedDistance: Number(data?.adminPassedDistance),
          adminPassedTA:
            oldCode?.adminPassedDistance != data?.adminPassedDistance
              ? adminPassedTACalculations(data, index)
              : data?.adminPassedTA,
          adminChangeReasonTA: data?.adminChangeReasonTA,
          hqTypeAdmin: hqType(data?.hqTypeAdmin),
          adminChangeReasonDA: data?.adminChangeReasonDA,
          adminPassedDA: Number(
            AdminPassedDaConditionalRendering(
              data,
              expenseApprovalsDaily,
              index,
            ) ?? 0,
          ),
        };
      }
      return obj;
    });
  };
  const onRowValueChanged = useCallback(
    (event: any) => {
      let data = event?.data;
      const index = expenseApprovalsDaily?.findIndex(
        (element: {expenseDay: any}) =>
          element?.expenseDay === data?.expenseDay,
      );

      let oldCode = expenseApprovalsDaily?.[index];
      if (
        oldCode?.adminPassedDistance != data?.adminPassedDistance &&
        data?.adminChangeReasonTA?.length >= 5 &&
        data?.adminChangeReasonTA?.length <= 50
      ) {
        dispatch(approveExpenseStateActions.setDisableaApproveExpense(false));
        dispatch(
          approveExpenseStateActions.setDaAmountCalculations(
            modifiedEmployees(data, oldCode, index),
          ),
        );
        dispatch(approveExpenseStateActions.setDailyEditedRowColorIndex([]));
        dispatch(approveExpenseStateActions.setDeductAmount({}));
        setTimeout(() => {
          dispatch(
            fetchDailyExpensePopupTADASaveData(
              request(data, expenseApprovalsDaily, index),
            ),
          );
        }, 500);
      } else if (
        (oldCode?.hqTypeAdmin != data?.hqTypeAdmin &&
          data?.adminChangeReasonDA?.length >= 5 &&
          data?.adminChangeReasonDA?.length <= 50) ||
        (Number(oldCode?.adminPassedDA) != Number(data?.adminPassedDA) &&
          data?.adminChangeReasonDA?.length >= 5 &&
          data?.adminChangeReasonDA?.length <= 50)
      ) {
        dispatch(approveExpenseStateActions.setDeductAmount({}));
        dispatch(approveExpenseStateActions.setDisableaApproveExpense(false));
        dispatch(
          approveExpenseStateActions.setDaAmountCalculations(
            modifiedEmployees(data, oldCode, index),
          ),
        );

        dispatch(
          approveExpenseStateActions.setDailyEditedRowColorIndexArr1([]),
        );
        setTimeout(() => {
          dispatch(
            fetchDailyExpensePopupTADASaveData(
              request(data, expenseApprovalsDaily, index),
            ),
          );
        }, 500);
      } else {
        condition(data, oldCode, index);
      }
    },
    [expenseApprovalsDaily, rowData, PopuptadaData],
  );

  const onRowEditingStarted = useCallback(
    event => {
      // setDcr_Id(event.data.dcrId)
      dispatch(fetchDailyExpensePopupTADAData(event?.data?.dcrId));
    },
    [rowData],
  );

  const handleColumnResized = (event: any) => {
    if (!event?.column) {
      return;
    }
    const {column} = event;
    setExpandedWidth(prev => ({
      ...prev,
      [column?.colId]: column.getActualWidth(),
    }));
  };

  util.onRowValueChanged = onRowValueChanged;
  util.onRowEditingStarted = onRowEditingStarted;
  util.style1 = style1;
  util.style = style;
  util.AdminPassedDaConditionalRendering = AdminPassedDaConditionalRendering;
  return (
    <>
      {rowData?.length > 0 && (
        <div
          data-testid="grid-react"
          className="ag-theme-alpine"
          style={{height: 370}}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columns}
            getRowId={getRowId}
            onRowValueChanged={onRowValueChanged}
            onRowEditingStarted={onRowEditingStarted}
            onColumnResized={handleColumnResized}
            columnTypes={columnTypes}
            editType={'fullRow'}
            getRowStyle={colorChange}
            enableRangeSelection={true}
            copyHeadersToClipboard={true}
            enableCellTextSelection={true}
            rowHeight={20}
            defaultColDef={{
              sortable: true,
              cellDataType: false,
              resizable: true,
              suppressMovable: true,
            }}></AgGridReact>

          <Box>
            <InsufficentFundPopup />
            <DcrModal />
            <TADAPopup setExpenseId={setExpenseId} />
            <TravleDetail />
          </Box>
        </div>
      )}
      {
        <ViewBillPopup
          isComingFromDailyGrid={true}
          expenseId={expenseId}
          setExpenseId={setExpenseId}
        />
      }
      {!rowData?.length && <NoRecordsFoundBox />}
      <div style={{marginTop: 2}}>
        {nameColor?.map((item: any) => {
          return (
            <span key={item?.name} style={referColor}>
              <span style={nameStyles(`${item?.color}`)}></span> {item?.name}
            </span>
          );
        })}
      </div>
    </>
  );
};

export default ApproveExpenseDailyGrid;
export const nameColor = [
  {
    name: strings.WEEKOFFHOLIDAYLEAVE,
    color: theme.colors.LIGHT_GREEN,
  },
  {
    name: strings.POLICYVIOLATION,
    color: theme.colors.pink[300],
  },
  {
    name: strings.EDITABLEROW,
    color: theme.colors.yellow[700],
  },
];
export const adminPassedTACalculations = (newRow: any, index: any) => {
  const clculatedValue =
    Number(newRow?.adminPassedDistance) * Number(newRow?.taRate);
  const formattedResult =
    clculatedValue % 1 !== 0 ? clculatedValue?.toFixed(1) : clculatedValue;
  return formattedResult;
};
export const adminPassedDACalulations = (
  newRow: any,
  index: any,
  expenseApprovalsDaily: any,
) => {
  if (newRow?.hqTypeAdmin !== expenseApprovalsDaily?.[index]?.hqTypeAdmin) {
    if (newRow?.hqTypeAdmin === 'E') {
      return hqTypeE_Selected(newRow);
    }
    if (newRow?.hqTypeAdmin === 'H') {
      return hqTypeHQ_Selected(newRow);
    }
    if (newRow?.hqTypeAdmin === 'O') {
      return hqTypeO_Selected(newRow);
    }
    return Number(newRow?.adminPassedDA);
  }
};
export const hqTypeE_Selected = (newRow: any) => {
  let value = 0;
  if (newRow?.daExAmount !== null) {
    value = newRow?.daExAmount;
  }
  return value;
};
export const hqTypeHQ_Selected = (newRow: any) => {
  let value = 0;
  if (newRow?.dahqAmount !== null) {
    value = newRow?.dahqAmount;
  }
  return value;
};
export const hqTypeO_Selected = (newRow: any) => {
  let value = 0;
  if (newRow?.daOutAmount !== null) {
    value = newRow?.daOutAmount;
  }
  return value;
};
