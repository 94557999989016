import Typography from '@mui/material/Typography';
import {
  Box,
  Stack,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchPendencyReportCreator,
  fetchStateAndDivisionCreator,
  inventoryModulePRActions,
} from '@app/screens/inventory-management/pages/PendencyReport/redux/slice';
import {inventoryPRSelector} from '@app/screens/inventory-management/pages/PendencyReport/redux/selectors';
import SearchOption from '@app/screens/inventory-management/pages/PendencyReport/components/SearchOption';
import ExcelPdfReport from '@app/components/ExportAsExcel';

const styles = {
  containerCNF: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  filterContainerCNF: {
    width: '99%',
    height: 'auto',
    top: '55px',
    left: '8px',
    display: 'flex',
    justifyContent: 'space-around',
    padding: '8px',
    borderRadius: '8px',
  },
  formControlCNF: {
    margin: '8px',
    width: '50%',
  },
  iconCNF: {
    marginTop: '4px',
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginLeft: 1,
  },
  formControl: {
    margin: '8px',
    backgroundColor: '#ffffff',
    borderWidth: 0.7,
    borderColor: '#322b7c',
    marginRight: 9,
    left: 7,
  },
  formControlSearch: {
    margin: '8px',
    backgroundColor: '#ffffff',
    borderWidth: 0.7,
    borderColor: '#322b7c',
    marginRight: 9,
    left: 47,
  },
  suggestionsContainer: {
    left: '50%',
    backgroundColor: '#d5e2e7',
    width: '32%',
    padding: '20px',
    position: 'fixed',
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface DIVISION {
  name: string;
  shortName: string;
  id: number;
}

const columnHeaders = [
  {id: 'SAP code', name: 'sapCode', title: 'SAP code'},
  {id: 'Reporting To', name: 'reportingTo', title: 'Reporting To'},
  {id: 'SAP code', name: 'itemCode', title: 'Item/Sample Code'},
  {id: 'SAP code', name: 'itemName', title: 'Item/Sample Name'},
  {id: 'SAP code', name: 'pack', title: 'Pack'},
  {id: 'SAP code', name: 'quantity', title: 'Total Qty'},
  {
    id: 'SAP code',
    name: 'pendingSince30Days',
    title: 'Pending Since More Than 30 Days',
  },
  {
    id: 'SAP code',
    name: 'pendingSince45Days',
    title: 'Pending Since More Than 45 Days',
  },
];

export const PendencyReportNavbar = () => {
  const dispatch = useDispatch();
  const divisions = useSelector(inventoryPRSelector.getSelectedDivision());
  const allDivisions = useSelector(inventoryPRSelector.getAllDivisions());
  const pendencyPayload = useSelector(
    inventoryPRSelector.getPendencyPayloadData(),
  );
  const pendencyReportData = useSelector(
    inventoryPRSelector.getPendencyReportData(),
  );

  const getExcelData = useSelector(
    inventoryPRSelector.getPendencyReportExcelData(),
  );
  const loader = useSelector(inventoryPRSelector.getLoading());
  useEffect(() => {
    dispatch(fetchStateAndDivisionCreator());
  }, []);

  const handleDivisionChange = (e: any) => {
    const divID: any =
      e.target.value === 0
        ? allDivisions?.map((item: any) => item.id)
        : [e.target.value];

    dispatch(inventoryModulePRActions.setSelectedDivision(divID));
    dispatch(inventoryModulePRActions.setUserData([]));
    dispatch(inventoryModulePRActions.setSearchValue([]));
  };
  const ExcelDataAPI = () => {
    const pendencyPayloadData = {
      pageNumber: 0,
      offsetValue: 0,
      stateIds: {
        stateId: pendencyPayload?.stateIds?.stateId,
      },
      divisionId: parseInt(pendencyPayload?.divisionId),

      hqIds: {
        hqId: pendencyPayload?.hqIds?.hqId,
      },
      userIds: pendencyPayload.userIds,
      isActive: pendencyPayload.isActive,
      isSample: null,
      searchValueItemSamples: pendencyPayload.searchValueItemSamples,
      apiFor: 'DownLoad',
    };
    dispatch(fetchPendencyReportCreator(pendencyPayloadData));
  };
  const resetExcelData = () => {
    dispatch(inventoryModulePRActions.setPendencyReportExcelData({}));
  };

  return (
    <Box sx={styles.containerCNF}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}>
        <Typography
          data-testid={'Pendency Report'}
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}
          marginLeft={'10px'}>
          Pendency Report
        </Typography>
        <Stack
          spacing={2}
          direction="row"
          alignContent="center"
          marginRight={1.7}>
          <ExcelPdfReport
            data={getExcelData?.adminPendencyReportDtos}
            fileName={'Pendency Report'}
            header={columnHeaders}
            title={'Download Report'}
            onClick={() => ExcelDataAPI()}
            resetFun={() => resetExcelData()}
            disableButton={
              pendencyReportData?.adminPendencyReportDtos
                ? pendencyReportData?.adminPendencyReportDtos?.length === 0
                : true
            }
          />
        </Stack>
      </Box>
      <Box display={'flex'}>
        <FormControl sx={styles.formControlCNF}>
          <InputLabel id="select-division-label">
            Select Division <span style={{color: 'red', fontSize: 20}}>*</span>
          </InputLabel>
          <Select
            inputProps={{
              'data-testid': 'selectDivision-dropdown',
            }}
            sx={{backgroundColor: '#ffffff'}}
            variant="outlined"
            labelId="select-division-label"
            label="Select Division"
            onChange={handleDivisionChange}
            disabled={loader}
            MenuProps={MenuProps}
            value={divisions}>
            {allDivisions &&
              allDivisions != undefined &&
              allDivisions?.length > 0 &&
              allDivisions?.map((divisionData: DIVISION) => {
                return (
                  <MenuItem value={divisionData.id} key={divisionData.id}>
                    {divisionData.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <SearchOption />
      </Box>
    </Box>
  );
};
